import { createWorkplace } from "api/graphql/mutations";
import { searchWorkplaceIncludingClientsByOffset } from "api/graphql/queries";
import { API, graphqlOperation } from "utils/graphqlOperation";
import { add as addAlert } from "ducks/Alert";
import { industrySectorsSelector } from "ducks/IndustrySectors";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { PhoneNumberFormatUtil } from "utils/format";
import { debugLog } from "utils/log";
import { useXGridComponents } from "utils/useXGridComponents";
import { EditableWorkplaceStatus } from "utils/Context";
import { getGridStringOperators } from "@mui/x-data-grid-pro";

const columns = [
  {
    field: "belongInCompanyName",
    headerName: "事業者",
    minWidth: 200,
    flex: 1,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "name",
    headerName: "名称",
    minWidth: 200,
    flex: 1,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "postalCode",
    headerName: "郵便番号",
    flex: 1,
    maxWidth: 135,
    minWidth: 135,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "prefecturesName",
    headerName: "都道府県",
    valueGetter: (params) => params.row.prefectures?.name,
    minWidth: 135,
    flex: 1,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "city",
    headerName: "市区町村",
    minWidth: 135,
    flex: 1,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "streetAddress",
    headerName: "町名",
    minWidth: 130,
    flex: 1,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "otherAddress",
    headerName: "町名以降",
    type: "text",
    minWidth: 200,
    flex: 1,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "headOfficeWorkplacePhone",
    headerName: "本社電話番号",
    minWidth: 170,
    flex: 1,
    valueFormatter: (params) =>
      PhoneNumberFormatUtil.formatNational(params.value),
    filterable: false,
  },
];

/**
 * 取引先事業場を選択するコンテナコンポーネントです。
 * @param {props} props プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  value,
  belongInCompanyId,
  onSelected = (data) => debugLog(data),
  onError = (err) => debugLog(err),
  autoHeight = false,
  visibleCompanyName = true,
  multiple = false,
  checkbox = false,
  download = true,
  title = "",
  fixedOptions,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [isSubmit, setIsSubmit] = useState(false);
  const industrySectors = useSelector(industrySectorsSelector);
  const xGridObject = useXGridComponents(
    visibleCompanyName === true
      ? columns
      : columns.filter((column) => column.field !== "belongInCompanyName"),
    searchWorkplaceIncludingClientsByOffset,
    fixedOptions,
    {
      sort: {
        direction: "desc",
        field: "belongInCompanyId",
      },
    }
  );

  const handleSelected = (params) => {
    if (!xGridObject.params.loading) {
      onSelected(
        xGridObject.params.rows.filter((row) => params.includes(row.id))
      );
    }
  };

  const onSubmit = (data) => {
    setIsSubmit(true);
    const { categories, phone, fax, industrySector, ...other } = data;
    API.graphql(
      graphqlOperation(createWorkplace, {
        input: {
          phone: phone
            ? PhoneNumberFormatUtil.formatInternational(phone)
            : null,
          fax: fax ? PhoneNumberFormatUtil.formatInternational(fax) : null,
          categoryIds: categories?.map((category) => category.id),
          state: EditableWorkplaceStatus.find((item) => item.name === "開店")
            .code,
          belongInCompanyId: belongInCompanyId,
          industrySectorId: industrySector?.id,
          ...other,
        },
      })
    )
      .then((res) => {
        setIsSubmit(false);
        dispatch(
          addAlert({
            value: "登録しました。",
            severity: "success",
          })
        );
        xGridObject.functions.refetch();
        setOpen(false);
      })
      .catch((err) => {
        setIsSubmit(false);
        dispatch(
          addAlert({
            value: "エラーが発生したため、登録できませんでした。",
            severity: "error",
          })
        );
      });
  };

  return render({
    isSubmit: isSubmit,
    onChangeSelection: handleSelected,
    open: open,
    value: value,
    onOpenDialog: () => setOpen(true),
    onCloseDialog: () => setOpen(false),
    xGridParams: xGridObject.params,
    autoHeight: autoHeight,
    multiple: multiple,
    download: download,
    industrySectors: industrySectors,
    onSubmit: onSubmit,
    belongInCompanyId: belongInCompanyId,
    checkbox: checkbox,
    ...props,
  });
};
