import { searchWasteIncludingClientsByOffset } from "api/graphql/queries";
import { useXGridComponents } from "utils/useXGridComponents";
import { getGridStringOperators } from "@mui/x-data-grid-pro";

const columns = [
  {
    field: "wasteLargeClassName",
    headerName: "廃棄物種類大分類",
    minWidth: 200,
    flex: 1,
    valueGetter: (params) => params.row.type?.largeClass?.name,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "wasteMiddleClassName",
    headerName: "廃棄物種類中分類",
    minWidth: 200,
    flex: 1,
    valueGetter: (params) => params.row.type?.middleClass?.name,
    hide: true,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "wasteSmallClassName",
    headerName: "廃棄物種類小分類",
    minWidth: 200,
    flex: 1,
    valueGetter: (params) => params.row.type?.smallClass?.name,
    hide: true,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "name",
    headerName: "名称",
    minWidth: 200,
    flex: 1,
    valueGetter: (params) => params.row.name,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
];

/**
 * 廃棄物を選択するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @fires Container#onSelected 選択したものを取得する？
 * @param {object} value 値
 * @param {object} props その他プロパティ
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  dischargeSourceWorkplaceId,
  onSelected,
  value,
  fixedOptions,
  onClose,
  ...props
}) => {
  const xGridObject = useXGridComponents(
    columns,
    searchWasteIncludingClientsByOffset,
    {
      ...fixedOptions,
      other: {
        dischargeSourceWorkplaceId: dischargeSourceWorkplaceId,
      },
    }
  );

  const handleSelected = (params) => {
    onSelected({
      value: xGridObject.params.rows.filter((row) => params.includes(row.id)),
      info: {
        search: xGridObject.search,
      },
    });
  };

  return render({
    ...props,
    onClose: onClose,
    value: value,
    xGridParams: xGridObject.params,
    onChangeSelect: handleSelected,
  });
};
