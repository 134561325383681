import { useStyles } from "./styles";
import { useState, useEffect } from "react";

/*
 object shape
   array
     title
     base64
     deletable
     download
*/

/**
 * スワイプ可能なPDF・画像のステップ表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {string} title タイトル
 * @fires Container#onClick クリック時
 * @fires Container#onDelete 削除時
 * @param {boolean} deletable 削除可能か
 * @param {string} base64 base64文字列
 * @param {boolean} download ダウンロード可能か
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  value = [],
  onClick = (data, index) => {},
  onDelete = (data, index) => {},
  start = 0,
  deletable = false,
  download = false,
  ...props
}) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(start);

  useEffect(() => {
    setActiveStep(start);
  }, [start]);

  const onNext = () => {
    setActiveStep(activeStep => (activeStep + 1));
  };

  const onBack = () => {
    setActiveStep(activeStep => (activeStep - 1));
  };

  const onChangeIndex = (swiperCore) => {
    setActiveStep(swiperCore.activeIndex);
  };

  return render({
    activeStep: activeStep,
    value: value,
    classes: classes,
    onClick: onClick,
    onNext: onNext,
    onBack: onBack,
    onDelete: onDelete,
    onChangeIndex: onChangeIndex,
    deletable: deletable,
    download: download,
    ...props,
  });
};
