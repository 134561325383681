import { useState } from "react";
import { debugLog } from "utils/log";
import { useStyles } from "./styles";

/**
 * ギャラリーを表示するコンテナコンポーネントです。
 * @param {func} render 引数を受けて、JSX.Elementを返すメソッド
 * @param {string} title タイトル
 * @param {object} value 値
 * @param {number} width サムネイルの幅
 * @param {number} height サムネイルの高さ
 * @param {boolean} deletable 削除可能設定
 * @param {boolean} download ダウンロード可能設定
 * @fires Container#onChange 変更時
 * @returns {JSX.Element}
 */
export const Container = ({
  render,
  title,
  value,
  onChange = (data) => debugLog("Gallery.onChange", data),
  width = 144,
  height = 144,
  deletable = false,
  download = true,
}) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [open, setOpen] = useState(false);

  const handleClick = (index) => {
    setActiveStep(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = (data, index) => {
    let result = [...value];
    result.splice(index, 1);
    onChange(result);
  };

  return render({
    open: open,
    activeStep: activeStep,
    dataSources: value,
    classes: classes,
    title: title,
    onDelete: handleDelete,
    onClick: handleClick,
    onClose: handleClose,
    deletable: deletable,
    download: download,
    width: width,
    height: height,
  });
};
