import { Close } from "@mui/icons-material";
import { Box, Card, CardContent, IconButton, Modal } from "@mui/material";
import React, { useEffect, useState, useCallback } from "react";
import { SwiperContainer, SwiperSlide } from "utils/SwiperWrapper";
import { isDataUri, urlToFile } from "utils/ImageFunctions";
import { debugLog } from "utils/log";
import { FileView } from "views/atoms";
import { Header } from "../Header";

/**
 * 配列の指定されたインデックスの要素を別の要素で置き換えます。
 *
 * @param {Array} array - 置換対象の配列
 * @param {number} index - 置換する要素のインデックス
 * @param {*} data - 置換する要素のデータ
 * @returns {Array} - 置換後の配列
 */
const swap = (array = [], index, data) => {
  let result = [...array];
  result.splice(index, 1, data);
  return result;
};

/**
 * モーダルのプレビューを表示するコンポーネントです。
 * @param {object} props プロパティ
 * @param {array} props.value ファイル配列
 * @param {integer} props.index ファイルのインデックス
 * @fires ModalPreview#onChange 変更時
 * @fires ModalPreview#onClose 閉じるとき
 * @param {JSX.Element} props.actions アクション要素
 * @param {boolean} props.disabled 無効設定
 * @returns {JSX.Element}
 */
export const ModalPreview = ({
  open = false,
  index = 0,
  value = [],
  onChange = () => {},
  onClose = () => {},
  actions = <></>,
  disabled = false,
}) => {
  const [fileIndex, setFileIndex] = useState(0);

  useEffect(() => {
    handleChangeIndex(index);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index]);

  const handleChangeIndex = useCallback(
    (index) => {
      setFileIndex(index);
      if (onChange) {
        if (!isDataUri(value[index].src)) {
          onChange(
            swap(value, index, {
              ...value[index],
              loading: true,
            })
          );
          urlToFile(value[index].src)
            .then((result) => {
              onChange(swap(value, index, result));
            })
            .catch((error) => {
              debugLog(
                "モーダルのプレビューのインデックス変更時エラー：",
                error
              );
            });
        }
      }
    },
    [value, onChange]
  );

  if (!value || value.length <= 0) {
    return <></>;
  }

  const handleDelete = (index) => (file) => {
    let result = [...value];
    result.splice(index, 1);
    setFileIndex((prevState) => (index === 0 ? index : prevState - 1));
    onChange(result);
  };

  if (index !== fileIndex) return null;

  return (
    <>
      <Modal open={open}>
        <>
          <SwiperContainer navigation={true} initialSlide={fileIndex}>
            {value.map((file, index) => (
              <SwiperSlide key={index}>
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100vw",
                    height: "100vh",
                  }}
                >
                  <Card
                    sx={{
                      overflow: "hidden",
                      maxWidth: "80vw",
                      maxHeight: "80vh",
                    }}
                  >
                    <CardContent>
                      <Header
                        src={file?.src}
                        label={file?.label}
                        name={file?.name}
                        onDelete={handleDelete(index)}
                        actions={actions}
                        disabled={disabled}
                      />
                      <Box
                        sx={{
                          overflow: "auto",
                          height: "100vh",
                        }}
                      >
                        <IconButton
                          sx={{
                            position: "fixed",
                            top: 0,
                            right: 0,
                            fontSize: "4rem",
                            color: "silver",
                          }}
                          onClick={onClose}
                        >
                          <Close fontSize="inherit" />
                        </IconButton>
                        <FileView src={file?.src} loading={file?.loading} />
                      </Box>
                    </CardContent>
                  </Card>
                </Box>
              </SwiperSlide>
            ))}
          </SwiperContainer>
        </>
      </Modal>
    </>
  );
};
