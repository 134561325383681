import { Alert, CircularProgress } from "@mui/material";
import { searchVehicleMaintenanceRecordsByOffset } from "api/graphql/queries";
import { companySelector } from "ducks/Company";
import { DateTime } from "luxon";
import { useImperativeHandle } from "react";
import { useSelector } from "react-redux";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { withForwardRef } from "utils/HoC";
import {
  fromQueryString,
  toQueryString,
  useXGridComponents,
} from "utils/useXGridComponents";
import { XGridComponent } from "views/molecules";
import { getGridStringOperators } from "@mui/x-data-grid-pro";

const columns = [
  {
    field: "vehicleNumber",
    headerName: "車輛ナンバー",
    minWidth: 170,
    flex: 1,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "maintenancedOn",
    headerName: "整備年月日",
    minWidth: 170,
    flex: 1,
    valueFormatter: (params) => {
      return params?.value
        ? DateTime.fromISO(params.value).toFormat("yyyy/MM/dd")
        : "";
    },
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "category",
    headerName: "整備種別",
    minWidth: 170,
    flex: 1,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "maintenanceCompanyName",
    headerName: "整備会社",
    minWidth: 170,
    flex: 1,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "price",
    headerName: "整備代金",
    minWidth: 170,
    flex: 1,
    renderCell: (params) => `${params.value?.toLocaleString()}円`,
    filterable: false,
  },
];

/**
 * 整備一覧を表示するコンポーネントです。
 * @param {object} props プロパティ
 * @param {array} rows 配列
 * @param {boolean} loading 読み込み中
 * @param {boolean} error エラー
 * @returns {JSX.Element}
 */
export const Maintenances = withForwardRef(
  ({ vehicleId, loading = false, error = null, _ref }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const company = useSelector(companySelector);
    const obj = useXGridComponents(
      columns,
      searchVehicleMaintenanceRecordsByOffset,
      {
        filter: {
          and: [
            {
              vehicleId: {
                eq: vehicleId,
              },
            },
          ],
        },
        other: {
          ownerCompanyId: company?.id,
        },
      },
      {
        sort: {
          direction: "desc",
          field: "maintenancedOn",
        },
        ...fromQueryString(location.search),
      }
    );

    useImperativeHandle(_ref, () => ({
      refresh: () => {
        obj.functions.refetch();
      },
    }));

    const handleSelected = (params) => {
      navigate(
        {
          pathname: location.pathname,
          search: `?${createSearchParams({
            ...Object.fromEntries(new URLSearchParams(location.search)),
            ...Object.fromEntries(
              new URLSearchParams(toQueryString(obj.search))
            ),
          }).toString()}`,
        },
        {
          replace: true,
        }
      );

      navigate(`${location.pathname}/maintenances/${params?.[0]}`);
    };

    if (error) {
      return (
        <Alert severity="error">
          {"エラーが発生したため、表示できませんでした。"}
        </Alert>
      );
    }

    if (loading) {
      return <CircularProgress />;
    }

    return (
      <XGridComponent
        onChangeSelection={handleSelected}
        checkboxSelection={false}
        multipleSelection={false}
        onDownload={true}
        onFilter={true}
        onColumns={true}
        {...obj.params}
      />
    );
  }
);
