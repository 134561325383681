import { searchVehicleSafetyInspectionRecordsByOffset } from "api/graphql/queries";
import { companySelector } from "ducks/Company";
import { DateTime } from "luxon";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  fromQueryString,
  toQueryString,
  useXGridComponents,
} from "utils/useXGridComponents";
import { getGridStringOperators } from "@mui/x-data-grid-pro";

const columns = [
  {
    field: "completedOn",
    headerName: "車検完了日",
    minWidth: 170,
    flex: 1,
    valueFormatter: (params) => {
      return params?.value
        ? DateTime.fromISO(params.value).toFormat("yyyy/MM/dd")
        : "";
    },
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "vehicleModelName",
    headerName: "車種",
    minWidth: 170,
    flex: 1,
  },
  {
    field: "vehicleNumber",
    headerName: "車輛ナンバー",
    minWidth: 170,
    flex: 1,
  },
  {
    field: "inspectionCompanyName",
    headerName: "受託会社",
    minWidth: 160,
    flex: 1,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "inspectionWorkplaceName",
    headerName: "実施場所",
    minWidth: 160,
    flex: 1,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "price",
    headerName: "車検代金",
    flex: 1,
    minWidth: 180,
    renderCell: (params) =>
      params.value ? `${params.value.toLocaleString()}円` : ``,
    filterable: false,
  },
];

/**
 * 車検記録簿を表示するコンテナコンポーネントです。
 */
export const Container = ({ render, ...props }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const company = useSelector(companySelector);
  const xGridObject = useXGridComponents(
    columns,
    searchVehicleSafetyInspectionRecordsByOffset,
    {
      other: {
        ownerCompanyId: company?.id,
      },
    },
    {
      sort: {
        direction: "desc",
        field: "completedOn",
      },
      ...fromQueryString(location.search),
    }
  );

  const handleSelected = (params) => {
    navigate(
      {
        pathname: location.pathname,
        search: toQueryString(xGridObject.search),
      },
      {
        replace: true,
      }
    );
    navigate(`${location.pathname}/${params?.[0]}`);
  };

  return render({
    ...props,
    onSelected: handleSelected,
    xGridParams: xGridObject.params,
  });
};
