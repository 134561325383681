import { Viewer } from "..";
import { IconButton } from "@mui/material";
import { SwiperContainer, SwiperSlide } from "utils/SwiperWrapper";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";

/**
 * スワイプ可能なPDF・画像のステップ表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <div className={props.classes.root}>
      {props.value.length > 1 && (
        <IconButton onClick={props.onBack} disabled={props.activeStep === 0}>
          <ChevronLeft />
        </IconButton>
      )}
      <SwiperContainer
        direction="horizontal"
        initialSlide={props.activeStep}
        autoHeight={true}
        onSlideChange={props.onChangeIndex}
      >
        {props.value.map((context, index) => (
          <SwiperSlide key={index}>
            <Viewer
              key={index}
              base64={context.base64}
              title={context.title}
              download={context.download || props.download}
              deletable={context.deletable || props.deletable}
              onDelete={props.onDelete(context, index)}
              onClick={props.onClick(context, index)}
            />
          </SwiperSlide>
        ))}
      </SwiperContainer>
      {props.value.length > 1 && (
        <IconButton
          onClick={props.onNext}
          disabled={props.activeStep === props.value.length - 1}
        >
          <ChevronRight />
        </IconButton>
      )}
    </div>
  );
};
