import { Thumbnail, MobileStepperViewer } from "views/atoms";
import { ModalPopup } from "views/molecules";

/**
 * ギャラリーを表示するプレゼンテーションコンポーネントです。
 * @param {object} props プロパティ
 * @returns {JSX.Element}
 */
export const Presententer = (props) => {
  return (
    <div className={props.classes.root}>
      {props.title && <div>{props.title}</div>}
      <div>
        {props.dataSources?.map((data, index) => {
          return (
            <Thumbnail
              base64={data.base64}
              title={data.title}
              key={index}
              onClick={props.onClick(index)}
              onDelete={props.onDelete(data, index)}
              deletable={props.deletable}
              width={props.width}
              height={props.height}
            />
          );
        }) || <></>}
      </div>
      <ModalPopup open={props.open} onClose={props.onClose}>
        <MobileStepperViewer
          value={props.dataSources}
          onDelete={props.onDelete}
          deletable={props.deletable}
          download={props.download}
        />
      </ModalPopup>
    </div>
  );
};
