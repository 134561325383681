import { createContainerProduct } from "api/graphql/mutations";
import { searchContainerProductsByOffset } from "api/graphql/queries";
import { companySelector } from "ducks/Company";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { toApiValue } from "utils/ImageFunctions";
import { useMutation } from "utils/useMutation";
import { useXGridComponents } from "utils/useXGridComponents";
import { toQueryString, fromQueryString } from "utils/useXGridComponents";
import { getGridStringOperators } from "@mui/x-data-grid-pro";

// todo: widthの細かい設定

export const columns = [
  {
    field: "number",
    headerName: "管理番号",
    flex: 1,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "name",
    headerName: "名称",
    flex: 1,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "manufacturerName",
    headerName: "メーカー",
    flex: 1,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "modelNumber",
    headerName: "品番",
    width: 160,
    flex: 1,
    filterOperators: getGridStringOperators().filter(
      (operator) => operator.value === "contains" || operator.value === "equals"
    ),
  },
  {
    field: "width",
    headerName: "幅(mm)",
    width: 150,
    hide: true,
    flex: 1,
    sortable: false,
    filterable: false,
  },
  {
    field: "height",
    headerName: "高さ(mm)",
    width: 150,
    hide: true,
    flex: 1,
    sortable: false,
    filterable: false,
  },
  {
    field: "depth",
    headerName: "奥行(mm)",
    width: 150,
    hide: true,
    flex: 1,
    sortable: false,
    filterable: false,
  },
  {
    field: "volume",
    headerName: "容積(㎥)",
    width: 150,
    hide: true,
    flex: 1,
    sortable: false,
    filterable: false,
  },
  {
    field: "numberOfActives",
    headerName: "総数",
    width: 160,
    flex: 1,
    filterable: false,
  },
  {
    field: "numberOfStorage",
    headerName: "保管数",
    width: 160,
    flex: 1,
    filterable: false,
  },
  {
    field: "numberOfInstallations",
    headerName: "設置数",
    width: 160,
    flex: 1,
    filterable: false,
  },
];

/**
 * コンテナ管理画面を表示するコンテナコンポーネントです。
 * @return {JSX.Element}
 */
export const Container = ({ render, ...props }) => {
  const [open, setOpen] = useState(false);
  const company = useSelector(companySelector);
  const navigate = useNavigate();
  const location = useLocation();
  const xGridObject = useXGridComponents(
    columns,
    searchContainerProductsByOffset,
    {
      other: {
        ownerCompanyId: company.id,
      },
    },
    {
      sort: {
        direction: "asc",
        field: "name",
      },
      ...fromQueryString(location.search),
    }
  );

  const onSelected = (params) => {
    navigate(
      {
        pathname: location.pathname,
        search: toQueryString(xGridObject.search),
      },
      {
        replace: true,
      }
    );
    navigate(`/master/container/category/list/${params[0].id}`);
  };

  const [createMutation, createMutationStatus] = useMutation(
    createContainerProduct,
    {
      onCompleted: (data) => {
        setOpen(false);
        xGridObject.functions.refetch();
      },
      succeedMessage: "登録しました。",
      errorMessage: "エラーが発生したため、登録できませんでした。",
    }
  );

  const handleRegister = async (data) => {
    const {
      number,
      name,
      manufacturerName,
      modelNumber,
      width,
      height,
      depth,
      volume,
      remarks,
      files,
    } = data;

    createMutation({
      input: {
        name: name,
        number: number,
        manufacturerName: manufacturerName,
        modelNumber: modelNumber,
        width: width,
        height: height,
        depth: depth,
        volume: volume,
        remarks: remarks,
        files: await toApiValue({ files: files }),
        ownerCompanyId: company.id,
      },
    });
  };

  return render({
    ...props,
    open: open,
    onCloseDialog: () => setOpen(false),
    onOpenDialog: () => setOpen(true),
    onSubmit: handleRegister,
    xGridParams: xGridObject.params,
    isSubmit: createMutationStatus.loading,
    onSelected: onSelected,
  });
};
